/* eslint-disable react-hooks/rules-of-hooks */
/*istanbul ignore file*/
import React from "react";
import { FlexColumn } from "../../../../../view/shared/base/FlexBox";
import Text from "../../../../../view/shared/base/Text";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import styles from "./styles.module.scss";
import Box from "@mui/material/Box";
import { assets } from "../../../../../constants/assets";

const WeddingTitle = () => {
  const { logo } = assets;
  return (
    <>
      <FlexColumn p={10} relative aiCenter jcCenter>
        <BoxImage
          mobile={logo}
          desktop={logo}
          sx={{
            height: 150,
          }}
        />
      </FlexColumn>

      <Box className={styles.root}>
        <FlexColumn relative aiCenter jcCenter className={styles.page}>
          <FlexColumn
            gap={3}
            p={10}
            jcCenter
            aiCenter
            height={{ xs: 150 }}
            sx={{ width: "100%" }}
          >
            <Text
              colorTextWhite
              alignCenter
              className={styles.title}
              sx={{ fontSize: { xs: 50, md: 50, lg: 60 } }}
            >
              Imelda y Nestor
            </Text>
            <Text
              colorTextWhite
              caption
              alignCenter
              lineHeight={1.5}
              letterSpacing={4}
            >
              22
              <br />
              Febrero
              <br />
              2025
            </Text>
          </FlexColumn>
        </FlexColumn>
      </Box>
    </>
  );
};

export default WeddingTitle;
