import React, { useState, useEffect } from "react";
import { FlexColumn } from "../../../view/shared/base/FlexBox";
import LoadingIndicator from "../../../view/shared/base/LoadingIndicator";
import Text from "../../shared/base/Text";
import BoxImage from "../../shared/base/Image/BoxImage";
import { assets } from "../../../constants/assets";

const PageNotAvailable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { logo } = assets;
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (isLoading) return <LoadingIndicator fullsize />;

  return (
    <FlexColumn
      fullHeight
      fullWidth
      jcCenter
      aiCenter
      sx={{
        width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
      }}
    >
      <FlexColumn relative aiCenter jcCenter>
        <BoxImage
          mobile={logo}
          desktop={logo}
          sx={{
            height: 150,
          }}
        />
      </FlexColumn>
      <Text h1 alignCenter colorTextRed lineHeight={1.5} sx={{ maxWidth: 550 }}>
        Ups, parece que no hemos podido encontrar la invitación que solicitaste.
      </Text>
    </FlexColumn>
  );
};

export default PageNotAvailable;
