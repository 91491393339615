const pathBase = "https://repo.agavesentreotros.com/images/";

export const assets = {
  logo: pathBase + "logo_general.png",
  mesaLiv: pathBase + "mesa-liv.png",
  counterBGHorizontal: pathBase + "bg_horizontal.jpg",
  countreBGVertical: pathBase + "bg_vertical.jpg",
  confirmaBGhorizontal: pathBase + "confirma_horizontal.jpg",
  confirmaBGvertical: pathBase + "confirma_vertical.jpg",
  codigoVestimienta: pathBase + "codigo.png",
  hotelFoto: pathBase + "hotel_foto.jpg",
  iglesia: pathBase + "icons/iglesia.png",
  recepcion: pathBase + "icons/recepcion.png",
  entrada: pathBase + "icons/entrada.png",
  cena: pathBase + "icons/cena.png",
  fiesta: pathBase + "icons/fiesta.png",
  catedral: pathBase + "catedral.jpg",
  cassia: pathBase + "Cassia.jpeg",
  papelTextura: pathBase + "papel-textura.jpg",
};
