/* eslint-disable react-hooks/rules-of-hooks */
/*istanbul ignore file*/
import React, { useState } from "react";
import { FlexRow, FlexColumn } from "../../../../shared/base/FlexBox";
import BoxImage from "../../../../shared/base/Image/BoxImage";
import Text from "../../../../shared/base/Text";
import styles from "./styles.module.scss";
import useLayout from "../../../../../hooks/useLayout";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import MainTitle from "../../../../shared/base/MainTitle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomSnackbar from "../../components/SnackBarMui";
import Alert from "@mui/material/Alert";
import { assets } from "../../../../../constants/assets";

const Confirma = ({ show, invitado, confirmaAsistencia }) => {
  if (!show) return null;
  const [textFieldValue, setTextFieldValue] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [selectPases, setSelectPases] = useState("");
  const [error, setError] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState({ open: false });
  const { open, message, severity } = openSnackBar;
  const { invitado_desc, confirma, comentarios, pases, pases_usados } =
    invitado || {};
  const { isDesktop } = useLayout();

  if (!invitado_desc) {
    return null;
  }

  const { confirmaBGhorizontal, confirmaBGvertical } = assets;

  const menuItems = [];
  for (let i = 0; i < pases; i++) {
    menuItems.push(
      <MenuItem key={i + 1} value={i + 1}>
        {i + 1}
      </MenuItem>
    );
  }

  const handleAcceptClick = () => {
    if (selectValue === "") {
      setError(true);
    } else {
      confirmaAsistencia({
        comments: textFieldValue,
        confirm: selectValue,
        pases_usados: selectPases,
      })
        .then((resp) => {
          setOpenSnackBar({
            open: true,
            message: resp.message,
            severity: resp.error ? "error" : "success",
          });
        })
        .catch((error) => {
          setOpenSnackBar({
            open: true,
            message: "Ocurrió un error:",
            severity: "error",
            error,
          });
        });
    }
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
    if (event.target.value === "") {
      setError(true);
    } else {
      setError(false);
    }
  };
  const handlePasesChange = (event) => {
    setSelectPases(event.target.value);
  };

  const cssDesktop = {
    position: "absolute",
    left: "50%",
    top: 0,
    transform: "translateX(-50%)",
  };
  const cssMobile = {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "85%",
  };

  return (
    <Box className={styles.root}>
      <FlexRow relative className={styles.page}>
        <CustomSnackbar
          open={open}
          alertText={message}
          alertSeverity={severity}
          handleClose={() => setOpenSnackBar(false)}
          autoHideDuration={6000}
        />

        <BoxImage
          mobile={isDesktop ? confirmaBGhorizontal : confirmaBGvertical}
          desktop={isDesktop ? confirmaBGhorizontal : confirmaBGvertical}
          sx={{ width: "100%" }}
        />
        <FlexColumn
          className="wedding-invite"
          sx={isDesktop ? { ...cssDesktop } : { ...cssMobile }}
        >
          <MainTitle title={"Confirmación"} fullWidth hideDivider />
          <FlexColumn
            sx={{
              paddingX: 0,
              backgroundColor: "white",
              opacity: 0.7,
              borderRadius: "10px",
            }}
          >
            <motion.div
              className="invite-card"
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              {confirma ? (
                <FlexColumn
                  fullWidth
                  py={{ xs: 2, md: 5 }}
                  gap={{ xs: 2, md: 5 }}
                  aiCenter
                  jcCenter
                >
                  <Text colorTextRed xs_h4 lg_h1>
                    {invitado_desc}
                  </Text>
                  <Text pt={{ xs: 3, md: 5 }} xs_body1 lg_h4>
                    ¡Ya has respondido a la invitación!
                  </Text>
                  <FlexRow>
                    <Text xs_body1 lg_h4>
                      Asitirás:{" "}
                      <Text xs_body1 lg_h4 colorTextRed>
                        {confirma === "1" ? "Sí" : "No"}
                      </Text>
                    </Text>
                  </FlexRow>
                  <FlexRow>
                    <Text xs_body1 lg_h4>
                      Pases usados:{" "}
                      <Text xs_body1 lg_h4 colorTextRed>
                        {" "}
                        {pases_usados}
                      </Text>
                    </Text>
                  </FlexRow>
                  <FlexRow px={3}>
                    <Text xs_body1 lg_h4 wrap alignCenter>
                      Comentarios:
                      <Text xs_body1 lg_h4 colorTextRed wrap alignCenter>
                        {comentarios}
                      </Text>
                    </Text>
                  </FlexRow>
                  <Text
                    px={5}
                    pt={{ xs: 3, md: 5 }}
                    lineHeight={1.5}
                    xs_body2
                    lg_h4
                    colorTextSecondary
                    wrap
                    alignCenter
                  >
                    Si tienes algún comentario adicional o quisieras contestar
                    de nuevo, por favor escribe a la novia o al novio
                    directamente.
                  </Text>
                </FlexColumn>
              ) : (
                <FlexColumn
                  px={10}
                  py={5}
                  aiCenter
                  jcCenter
                  gap={{ xs: 2, md: 5 }}
                >
                  <Text xs_caption lg_h1 alignCenter>
                    ¡Nos encantaría contar con tu presencia!
                  </Text>
                  <FormControl fullWidth>
                    <InputLabel sx={{ fontSize: { xs: 17, md: 20 } }}>
                      ¿Asistes?
                    </InputLabel>
                    <Select
                      value={selectValue}
                      label={"Asistes"}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value={1}>Sí</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel sx={{ fontSize: { xs: 17, md: 20 } }}>
                      Pases a usar
                    </InputLabel>
                    <Select
                      value={selectPases}
                      label={"Asistes"}
                      onChange={handlePasesChange}
                    >
                      {menuItems}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="filled-multiline-static"
                      label="Inserta tus comentarios aquí"
                      multiline
                      rows={2}
                      variant="filled"
                      value={textFieldValue}
                      onChange={handleTextFieldChange}
                    />
                  </FormControl>
                  {error && (
                    <Alert severity="error">
                      Por favor selecciona si podrías asistir
                    </Alert>
                  )}
                  <motion.button
                    className={styles.acceptButton}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={handleAcceptClick}
                  >
                    ¡Enviar respuesta!
                  </motion.button>
                </FlexColumn>
              )}
            </motion.div>
          </FlexColumn>
        </FlexColumn>
      </FlexRow>
    </Box>
  );
};

export default Confirma;
