import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "@mui/icons-material/Work";
import { FlexColumn } from "../../../../shared/base/FlexBox";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import MainTitle from "../../../../shared/base/MainTitle";
import "./styles.css";
import Divider from "../../../../shared/base/Divider";
import { assets } from "../../../../../constants/assets";

export default function TimelineComponent() {
  const { iglesia, recepcion, entrada, cena, fiesta } = assets;
  const Icon = ({ image }) => {
    return (
      <BoxImage
        mobile={image}
        desktop={image}
        sx={{
          width: "100%",
          minWidth: 45,
          maxWidth: 450,
        }}
      />
    );
  };

  const array = [
    {
      title: "Ceremonia Religiosa",
      subtitle: "Catedral León",
      date: "5:00 P.M.",
      icon: <Icon image={iglesia} />,
    },
    {
      title: "Recepción",
      subtitle: "Hacienda La Cassia",
      date: "7:00 P.M.",
      icon: <Icon image={recepcion} />,
    },
    {
      title: "Entrada de novios",
      subtitle: "Hacienda La Cassia",
      date: "7:30 P.M.",
      icon: <Icon image={entrada} />,
    },
    {
      title: "Cena ",
      subtitle: "Hacienda La Cassia",
      date: "8:00 P.M.",
      icon: <Icon image={cena} />,
    },
    {
      title: "Fiesta",
      subtitle: "Hacienda La Cassia",
      date: "9:00 P.M.",
      icon: <Icon image={fiesta} />,
    },
  ];

  const getVerticalTimelineElement = ({
    title = "",
    subtitle = "",
    date = "",
    icon = <WorkIcon />,
    iconStyle = {},
    contentStyle = {},
    contentArrowStyle = {},
    description = "",
  }) => {
    return (
      <VerticalTimelineElement
        contentStyle={{
          ...contentStyle,
          marginLeft: 115,
          background: "#AEA69D",
          color: "#fff",
        }}
        contentArrowStyle={{
          ...contentArrowStyle,
          borderRight: "7px solid #AEA69D",
        }}
        date={date}
        iconStyle={{
          width: "100px",
          height: "100px",
          background: "#fff",
          color: "#fff",
          ...iconStyle,
        }}
        icon={icon}
      >
        <h3 className="vertical-timeline-element-title">{title}</h3>
        <h4 className="vertical-timeline-element-subtitle">{subtitle}</h4>
        <p>{description}</p>
      </VerticalTimelineElement>
    );
  };

  return (
    <FlexColumn fullWidth fullHeight jcCenter aiCenter>
      <MainTitle title="Timeline" />
      <VerticalTimeline
        lineColor={"#da5b00"}
        className="vertical-timeline"
        layout={"1-column-left"}
      >
        {array.map((item, index) =>
          getVerticalTimelineElement({
            title: item.title,
            subtitle: item.subtitle,
            date: item.date,
            icon: item.icon,
            iconStyle: item.iconStyle,
            contentStyle: item.contentStyle,
            contentArrowStyle: item.contentArrowStyle,
            description: item.description,
          })
        )}
      </VerticalTimeline>
      <Divider sx={{ marginTop: 10 }} />
    </FlexColumn>
  );
}
