import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import MainTitle from "../../../../shared/base/MainTitle";
import useLayout from "../../../../../hooks/useLayout";
import { FlexColumn } from "../../../../shared/base/FlexBox";
import Divider from "../../../../shared/base/Divider";
import "react-image-gallery/styles/css/image-gallery.css";

const Gallery = () => {
  const [imgs, setImgs] = useState([]);
  const { isDesktop } = useLayout();

  const label = isDesktop ? "horizontal" : "vertical";
  useEffect(() => {
    if (!label) return;

    let isMounted = true;
    let promises = [];
    let tempImgs = [];

    for (let i = 1; i < 33; i++) {
      const image = `https://repo.agavesentreotros.com/images/gallery/${label}/sin título-${i}.jpg`;

      let promise = fetch(image, { method: "HEAD" })
        .then((response) => {
          if (response.ok) {
            tempImgs.push({ original: image, thumbnail: image });
          }
        })
        .catch(() => {});

      promises.push(promise);
    }

    Promise.all(promises).then(() => {
      if (isMounted) {
        setImgs(tempImgs);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [label]);

  return (
    <FlexColumn jcCenter aiCenter pt={6}>
      <MainTitle title="¡Nuestros momentos felices!" />
      <ImageGallery showThumbnails={false} items={imgs} />
      <Divider />
    </FlexColumn>
  );
};

export default Gallery;
