import React from "react";
import { FlexColumn } from "../FlexBox";
import { bgImageStyle } from "../../../../utils/image";
import { assets } from "../../../../constants/assets";

const PapperCardBase = ({ children = {}, sx = {} }) => {
  const { papelTextura } = assets;
  return (
    <FlexColumn
      py={10}
      gap={3}
      aiCenter
      sx={{
        width: "100%",
        minWidth: 200,
        maxWidth: { xs: "90%", md: 500, lg: 700 },
        borderRadius: "15px",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.3)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "repeat",
        ...bgImageStyle(papelTextura),
        ...sx,
      }}
    >
      {children}
    </FlexColumn>
  );
};

export default PapperCardBase;
